export const required = (errorMessage) => (value) => (value && value.length > 0
  ? undefined
  : errorMessage
);

export const minLength = (min, errorMessage) => (value) => ((value.length >= min)
  ? undefined
  : errorMessage
);

export const maxLength = (max, errorMessage) => (value) => ((value.length <= max)
  ? undefined
  : errorMessage
);

export const amount = (errorMessage) => (value) => !value || (value.match(/^\d+$/g) ? undefined : errorMessage);

export const composeValidators = (validators) => (value) => validators.reduce(
  (error, validator) => error || (validator ? validator(value) : undefined), undefined,
);
