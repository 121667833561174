import { connect } from 'react-redux';
import { compose } from 'underscore';
import { initializeSOWCollectionThunk } from '../duck';
import SOWCollectionApp from './sow_collection_app';

const mapStateToProps = (state) => ({
  initializing: state.sowCollection.initializing,
  successSubmitModalVisible: state.sowCollection.successSubmitModalVisible,
  verificationPending: state.sowCollection.formConfiguration.verificationPending,
});

const mapDispatchToProps = (dispatch) => ({
  initializeSOWCollectionThunk: compose(dispatch, initializeSOWCollectionThunk),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOWCollectionApp);
