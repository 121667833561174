import { connect } from 'react-redux';
import { compose } from 'underscore';
import { onChangeAdditionalIncome } from 'SOWCollection/duck';
import SOWCollectionAdditionalIncomeGroup from './sow_collection_additional_income_group';

const mapStateToProps = (state) => ({
  fields: state.sowCollection.formConfiguration.step2,
  values: state.sowCollection.sowData.additionalIncomes,
  forceValidation: state.sowCollection.formConfiguration.forceValidation,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: compose(dispatch, onChangeAdditionalIncome),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOWCollectionAdditionalIncomeGroup);
