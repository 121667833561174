import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import { t } from 'bv-i18n';

const SOWCollectionFreetext = ({
  name, placeholder, forceValidation, validators, onChange,
}) => (
  <Field
    name={name}
    validate={validators}
  >
    {({ input, meta }) => {
      const isError = meta.error && (meta.visited || forceValidation);

      return (
        <div className="bvs-form-group">
          <input
            {...input}
            onChange={(e) => {
              input.onChange(e.target.value);
              onChange(e);
            }}
            id={input.name}
            type="text"
            className={classnames('bvs-form-control', {
              error: isError,
              'with-value': input.value && input.value !== '',
            })}
          />
          <label className="bvs-form-control-placeholder" htmlFor={input.name}>{t(placeholder)}</label>
          {isError && (
          <span className="bvs-form-error-msg">
            {meta.error}
          </span>
          )}
        </div>
      );
    }}
  </Field>
);

SOWCollectionFreetext.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  validators: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SOWCollectionFreetext;
