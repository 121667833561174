import { connect } from 'react-redux';
import { compose } from 'underscore';
import { submitSOWCollectionForm } from '../duck';
import SOWCollectionForm from './sow_collection_form';

const mapStateToProps = (state) => ({
  formConfiguration: state.sowCollection.formConfiguration,
  currentStep: state.sowCollection.stepper.currentStep,
  initialValues: state.sowCollection.sowData,
});

const mapDispatchToProps = (dispatch) => ({
  submitSOWCollectionForm: compose(dispatch, submitSOWCollectionForm),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOWCollectionForm);
