import { useState } from 'react';
import { t } from 'bv-i18n';
import { Icon } from 'bv-components';

const SOWCollectionHowItWorks = () => {
  const tKeyPrefix = 'sow_collection.how_does_it_work';
  const [textExpanded, setTextExpanded] = useState(false);
  return (
    <div className="sow-collection-expanded">
      <div className="sow-collection-expanded__header bvs-link" onClick={() => setTextExpanded(!textExpanded)}>
        <h2 className="bvs-header">
          {t(`${tKeyPrefix}.title`)}
        </h2>
        <Icon
          className="bvs-link"
          id={textExpanded ? 'arrow-solid-circle-up' : 'arrow-solid-circle-down'}
        />
      </div>
      <div className="sow-collection-expanded__body">
        {textExpanded && (
          <>
            {t(`${tKeyPrefix}.long_description`)
              .split(/\\n|<br\/>/).map((line, index) => (
                <p key={line} id={index} className="bvs-p">{line}</p>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SOWCollectionHowItWorks;
