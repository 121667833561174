import { connect } from 'react-redux';
import { compose } from 'underscore';
import {
  removeAdditionalIncomeGroup,
  addAdditionalIncomeGroup,
} from '../duck';
import SOWCollectionAdditionalStepForm from './sow_collection_additional_step_form';

const mapStateToProps = (state) => ({
  fields: state.sowCollection.formConfiguration.step2,
  additionalIncomes: state.sowCollection.sowData.additionalIncomes,
  additionalIncomeLimit: state.sowCollection.formConfiguration.additionalSourceOfIncomeLimit,
  areAdditionalIncomes: state.sowCollection.sowData.additionalIncome,
});

const mapDispatchToProps = (dispatch) => ({
  removeAdditionalIncomeGroup: compose(dispatch, removeAdditionalIncomeGroup),
  addAdditionalIncomeGroup: compose(dispatch, addAdditionalIncomeGroup),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOWCollectionAdditionalStepForm);
