import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { t } from 'bv-i18n';

const SOWCollectionSelect = ({
  name, placeholder, className, validators, forceValidation, incomePeriods, onChange,
}) => (
  <Field
    name={name}
    validate={validators}
  >
    {({ input, meta }) => {
      const isError = meta.error && (meta.visited || forceValidation);
      return (
        <div className={className}>
          <select
            {...input}
            onChange={onChange}
            className={classnames('bvs-form-control', { error: isError })}
          >
            <option value="" disabled>{t(placeholder)}</option>
            {incomePeriods.map((option) => (
              <option value={option.periodKey} key={option.periodKey}>
                {t(option.periodKey)}
              </option>
            ))}
          </select>
          {isError && (
          <span className="bvs-form-error-msg">
            {meta.error}
          </span>
          )}
        </div>
      );
    }}
  </Field>
);

SOWCollectionSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  validators: PropTypes.instanceOf(Object).isRequired,
  incomePeriods: PropTypes.instanceOf(Object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  incomePeriods: state.sowCollection.formConfiguration.incomePeriods,
});

export default connect(mapStateToProps)(SOWCollectionSelect);
