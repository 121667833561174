import { getSOWCollectionSettings, postSOW } from './api';

const RESET_STATE = 'sowCollection/RESET';
const SET_INITIALIZED = 'sowCollection/SET_INITIALIZED';
const SET_FORM_CONFIGURATION = 'sowCollection/SET_FORM_CONFIGURATION';
const SHOW_STEPPER = 'sowCollection/SHOW_STEPPER';
const SHOW_SUCCESS_MODAL = 'sowCollection/SHOW_SUCCESS_MODAL';
const SET_SOW_FORM_DATA = 'sowCollection/SET_SOW_FORM_DATA';
const SET_CURRENT_STEP = 'sowCollection/SET_CURRENT_STEP';
const SET_FORCE_VALIDATION = 'sowCollection/SET_FORCE_VALIDATION';
const SET_ADDITIONAL_INCOME = 'sowCollection/SET_ADDITIONAL_INCOME';
const SET_AVERAGE_SPEND = 'sowCollection/SET_AVERAGE_SPEND';

const defaultConfiguration = {
  keysStep1: ['companyName', 'jobTitle', 'jobTenure', 'salaryYear', 'additionalIncome'],
  keysStep2: ['sourceOfIncome', 'amount', 'period'],
};

const initialState = {
  initializing: true,
  formConfiguration: {
    step1: [],
    step2: [],
    incomePeriods: [],
    additionalSourceOfIncomeLimit: 5,
    forceValidation: false,
    verificationPending: null,
  },
  averageSpend: {
    visible: false,
    amount: 0,
  },
  stepper: {
    currentStep: 1,
    visible: false,
    stepperWithoutProgressBar: [16, 20],
  },
  successSubmitModalVisible: false,
  sowData: {
    companyName: null,
    jobTitle: null,
    jobTenure: null,
    salaryYear: null,
    additionalIncome: null,
    additionalIncomes: [],
  },
  validation: {
    1: {
      minLength: 2,
      maxLength: 50,
    },
    2: {
      minLength: 1,
      maxLength: 50,
    },
    6: {
      minLength: 1,
      maxLength: 50,
    },
    7: {
      minLength: 1,
      maxLength: 50,
    },
    10: {
      minLength: 1,
      maxLength: 50,
    },
  },
};

const resetState = () => ({
  type: RESET_STATE,
});

const setInitialized = () => ({
  type: SET_INITIALIZED,
});

const setFormConfiguration = (configuration) => ({
  type: SET_FORM_CONFIGURATION,
  payload: configuration,
});

const showStepper = () => ({
  type: SHOW_STEPPER,
});

const showSuccessSubmitModal = () => ({
  type: SHOW_SUCCESS_MODAL,
});

const setSOWFormData = (fieldName, value) => ({
  type: SET_SOW_FORM_DATA,
  payload: {
    [fieldName]: value,
  },
});

const setAdditionalIncome = (additionalIncomesArray) => ({
  type: SET_ADDITIONAL_INCOME,
  payload: additionalIncomesArray,
});

export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});

export const setForceValidation = () => ({
  type: SET_FORCE_VALIDATION,
});

export const setAverageSpend = (amount) => ({
  type: SET_AVERAGE_SPEND,
  payload: amount,
});

const ifFieldBelongToStep = (key, keys) => keys.indexOf(key) >= 0;

export const initializeSOWCollectionThunk = () => async (dispatch) => {
  dispatch(resetState());
  const settings = await getSOWCollectionSettings();

  const configuration = { ...initialState.formConfiguration };

  configuration.verificationPending = !!settings?.verificationPending;

  settings.formSettings.forEach((fieldSetting) => {
    if (ifFieldBelongToStep(fieldSetting.questionName, defaultConfiguration.keysStep1)) {
      configuration.step1 = [...configuration.step1, fieldSetting];
    } else if (ifFieldBelongToStep(fieldSetting.questionName, defaultConfiguration.keysStep2)) {
      configuration.step2 = [...configuration.step2, fieldSetting];
    }
  });

  if (settings?.incomePeriods) {
    configuration.incomePeriods = [...settings.incomePeriods];
  }

  if (!Number.isNaN(Number.parseFloat(settings?.averageSpend))) {
    dispatch(setAverageSpend(settings?.averageSpend));
  }

  dispatch(setFormConfiguration(configuration));
  if (configuration.step2.length) {
    dispatch(showStepper());
  } else {
    dispatch(setSOWFormData('additionalIncome', false));
  }

  if (settings.questionnaire) {
    Object.keys(settings.questionnaire).forEach((key) => {
      if (key === 'additionalIncomes') {
        dispatch(setAdditionalIncome(settings.questionnaire[key]));
        if (configuration.step2.length) {
          dispatch(setSOWFormData('additionalIncome', settings.questionnaire[key]?.length > 0));
        }
      } else {
        dispatch(setSOWFormData(key, settings.questionnaire[key]));
      }
    });
  }

  dispatch(setInitialized());
};

export const submitSOWCollectionForm = (sowData) => async (dispatch) => {
  const postSOWDataResult = await postSOW(
    sowData.additionalIncome ? sowData : { ...sowData, additionalIncomes: [] },
  );
  if (postSOWDataResult.success) {
    dispatch(showSuccessSubmitModal());
  }
};

export const addAdditionalIncomeGroup = () => (dispatch, getState) => {
  const { sowCollection: sowCollectionState } = getState();
  const {
    additionalSourceOfIncomeLimit,
    step2: additionalFields,
  } = sowCollectionState.formConfiguration;
  const additionalIncomes = [...sowCollectionState.sowData.additionalIncomes];

  if (additionalIncomes.length < additionalSourceOfIncomeLimit) {
    const names = {};
    additionalFields.forEach((field) => { names[field.questionName] = null; });
    additionalIncomes.push(names);
    dispatch(setAdditionalIncome(additionalIncomes));
  }
};

export const removeAdditionalIncomeGroup = (index) => (dispatch, getState) => {
  const { sowCollection: sowCollectionState } = getState();
  const additionalIncomes = [...sowCollectionState.sowData.additionalIncomes];
  if (additionalIncomes.length > 1) {
    additionalIncomes.splice(index, 1);
    dispatch(setAdditionalIncome(additionalIncomes));
  }
};

export const onChangeMainSource = (fieldName, value) => (dispatch) => {
  dispatch(setSOWFormData(fieldName, value));

  if (fieldName === 'additionalIncome' && value) {
    dispatch(setCurrentStep(2));
  }
};

export const onChangeAdditionalIncome = (index, fieldName, value) => (dispatch, getState) => {
  const { sowCollection: sowCollectionState } = getState();
  const additionalIncomes = [...sowCollectionState.sowData.additionalIncomes];
  if (additionalIncomes[index]) {
    additionalIncomes[index][fieldName] = value;
    dispatch(setAdditionalIncome(additionalIncomes));
  }
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case RESET_STATE:
      return { ...initialState };
    case SET_INITIALIZED:
      return {
        ...state,
        initializing: false,
      };
    case SET_FORM_CONFIGURATION:
      return {
        ...state,
        formConfiguration: {
          ...action.payload,
        },
      };
    case SHOW_STEPPER:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          visible: true,
        },
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          currentStep: action.payload,
        },
        formConfiguration: {
          ...state.formConfiguration,
          forceValidation: false,
        },
      };
    case SET_FORCE_VALIDATION:
      return {
        ...state,
        formConfiguration: {
          ...state.formConfiguration,
          forceValidation: true,
        },
      };
    case SHOW_SUCCESS_MODAL:
      return {
        ...state,
        successSubmitModalVisible: true,
      };
    case SET_SOW_FORM_DATA:
      return {
        ...state,
        sowData: {
          ...state.sowData,
          ...action.payload,
        },
      };
    case SET_ADDITIONAL_INCOME:
      return {
        ...state,
        sowData: {
          ...state.sowData,
          additionalIncomes: [
            ...action.payload,
          ],
        },
      };
    case SET_AVERAGE_SPEND:
      return {
        ...state,
        averageSpend: {
          visible: true,
          amount: action.payload,
        },
      };
    default:
      return state;
  }
};
