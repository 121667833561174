import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Button } from 'bv-components';
import { t } from 'bv-i18n';
import StepsContainer from './steps_bar/steps_container';
import SOWCollectionMainStepFormContainer from './sow_collection_main_step_form_container';
import SOWCollectionAdditionalStepFormContainer from './sow_collection_additional_step_form_container';
import SOWCollectionFooter from './sow_collection_footer';
import SOWCollectionHowItWorks from './sow_collection_how_it_works';
import SOWCollectionAverageSpendContainer from './sow_collection_average_spend_container';

const sowFormStepComponents = {
  1: SOWCollectionMainStepFormContainer,
  2: SOWCollectionAdditionalStepFormContainer,
};

const SOWCollectionForm = ({ currentStep, submitSOWCollectionForm, initialValues }) => {
  const SOWFormStepComponent = sowFormStepComponents[currentStep];
  return (
    <div className="sow-collection-container">
      <Form
        initialValues={initialValues}
        onSubmit={submitSOWCollectionForm}
      >
        {({
          handleSubmit, submitting, invalid,
        }) => (
          <>
            <StepsContainer formInvalid={invalid || initialValues.additionalIncome === null} />
            <h2 className="bvs-header">
              {t(`sow_collection.step${currentStep}.title`)}
            </h2>
            <form>
              <SOWFormStepComponent formInvalid={invalid} />
              {(initialValues.additionalIncome === false
                || currentStep === Object.keys(sowFormStepComponents).length)
              && (
                <div className="sow-collection-submit-btn">
                  <Button
                    type="submit"
                    label={t('sow_collection.submit_button')}
                    primary
                    disabled={invalid || submitting}
                    onClick={handleSubmit}
                  />
                </div>
              )}
            </form>
          </>
        )}
      </Form>
      <SOWCollectionAverageSpendContainer />
      <SOWCollectionHowItWorks />
      <SOWCollectionFooter />
    </div>
  );
};

SOWCollectionForm.propTypes = {
  currentStep: PropTypes.number.isRequired,
  submitSOWCollectionForm: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
};

export default SOWCollectionForm;
