import PropTypes from 'prop-types';
import { Card } from 'bv-components';
import SOWCollectionFieldFactory from './fields/sow_collection_field_factory';

const SOWCollectionMainStepForm = ({
  fields,
  values,
  formInvalid,
  forceValidation,
  onChangeMainSource,
}) => (
  <Card className="sow-collection-form">
    {fields.map((field) => {
      const name = field.questionName;
      return (
        <SOWCollectionFieldFactory
          key={name}
          name={name}
          type={field.type}
          placeholder={field.titleTranslationKey}
          formInvalid={formInvalid}
          forceValidation={forceValidation}
          value={values[field.questionName]}
          onChange={(e) => onChangeMainSource(field.questionName, e.target.value)}
        />
      );
    })}
  </Card>
);

SOWCollectionMainStepForm.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onChangeMainSource: PropTypes.func.isRequired,
};

export default SOWCollectionMainStepForm;
