import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import StepsWithoutBar from './steps_without_bar';
import StepsWithBar from './steps_with_bar';

const Steps = ({
  visible,
  currentStep,
  setCurrentStep,
  formInvalid,
  forceValidation,
  areAdditionalIncomes,
  stepperWithoutProgressBar,
  setForceValidation,
}) => {
  if (!visible) return null;

  const steps = {
    1: { number: 1, name: 'step1', title: t('sow_collection.stepper.step1') },
    2: { number: 2, name: 'step2', title: t('sow_collection.stepper.step2') },
  };

  return stepperWithoutProgressBar.indexOf(bvVar('brandId')) > -1
    ? (
      <StepsWithoutBar
        currentStep={steps[currentStep].number}
        numberOfSteps={Object.keys(steps).length}
        setCurrentStep={() => setCurrentStep(steps[currentStep].number - 1)}
      />
    ) : (
      <StepsWithBar
        currentStep={steps[currentStep].number}
        steps={steps}
        forceValidation={forceValidation}
        formInvalid={formInvalid}
        setCurrentStep={
          (formInvalid || !areAdditionalIncomes) && currentStep !== Object.keys(steps).length
            ? setForceValidation
            : setCurrentStep
        }
      />
    );
};

Steps.propTypes = {
  visible: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  stepperWithoutProgressBar: PropTypes.instanceOf(Array).isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  areAdditionalIncomes: PropTypes.bool.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  setForceValidation: PropTypes.func.isRequired,
};

export default Steps;
