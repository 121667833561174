import { withModalWindow } from 'bv-hocs';
import SOWCollectionAppContainer from './sow_collection_app_container';

export default withModalWindow(
  'kycstrategy.26.card.title',
  {
    className: 'sow-collection-modal',
    showBack: true,
  },
)(SOWCollectionAppContainer);
