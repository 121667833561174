import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';
import SOWCollectionAdditionalIncomeGroupContainer from './fields/sow_collection_additional_income_group_container';

const SOWCollectionAdditionalStepForm = ({
  additionalIncomes,
  additionalIncomeLimit,
  areAdditionalIncomes,
  removeAdditionalIncomeGroup,
  addAdditionalIncomeGroup,
  formInvalid,
}) => {
  useEffect(() => {
    if (!additionalIncomes.length && areAdditionalIncomes) {
      addAdditionalIncomeGroup();
    }
  }, []);

  if (!areAdditionalIncomes) return null;

  return (
    <>
      {additionalIncomes.map((values, index) => (
        <div key={values} className="sow-collection-additional-source">
          <div className="sow-collection-additional-source__header">
            <h2 className="bvs-header">{t('sow_collection.source_of_income.title')}</h2>
            {additionalIncomes.length > 1 && (
              <Icon
                id="trash"
                className="bvs-link"
                onClick={() => removeAdditionalIncomeGroup(index)}
                type="sow-additional-source-trash-icon"
              />
            )}
          </div>
          <SOWCollectionAdditionalIncomeGroupContainer
            index={index}
            formInvalid={formInvalid}
          />
        </div>
      ))}
      <div
        className={classnames('sow-collection-add-more-source bvs-link', {
          disabled: additionalIncomes.length >= additionalIncomeLimit,
        })}
        onClick={addAdditionalIncomeGroup}
      >
        {t('sow_collection.source_of_income.add_more')}
        <span className={classnames('is-plus-icon', {
          'is-inactive': additionalIncomes.length >= additionalIncomeLimit,
        })}
        />
      </div>
    </>
  );
};

SOWCollectionAdditionalStepForm.propTypes = {
  additionalIncomes: PropTypes.instanceOf(Array).isRequired,
  additionalIncomeLimit: PropTypes.number.isRequired,
  areAdditionalIncomes: PropTypes.bool.isRequired,
  removeAdditionalIncomeGroup: PropTypes.func.isRequired,
  addAdditionalIncomeGroup: PropTypes.func.isRequired,
  formInvalid: PropTypes.bool.isRequired,
};

export default SOWCollectionAdditionalStepForm;
