import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { toCurrency } from 'bv-helpers/number';

const SOWCollectionAverageSpend = ({ visible, amount }) => {
  if (!visible) return null;

  return (
    <span className="sow-collection-average-spend">
      {t('sow_collection.average_monthly_spend', { amount: toCurrency(amount, { decimalPlaces: 2 }) })}
    </span>
  );
};

SOWCollectionAverageSpend.propTypes = {
  visible: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
};

export default SOWCollectionAverageSpend;
