import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';

const SOWCollectionSuccessModal = ({ onClose }) => {
  const tKeyPrefix = 'sow_collection.modal.success';
  return (
    <Modal
      success
      actions={[{
        id: 'ok-btn',
        label: t(`${tKeyPrefix}.button`),
        primary: true,
        inverse: true,
        close: true,
        onClick: onClose,
      }]}
    >
      <h3 className="bvs-modal__title">
        {t(`${tKeyPrefix}.title`)}
      </h3>
      <p>
        {t(`${tKeyPrefix}.body`)}
      </p>
    </Modal>
  );
};

SOWCollectionSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SOWCollectionSuccessModal;
