import SOWCollectionModal from './components/sow_collection_modal';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({ sowCollection: reducer });

const SOWCollection = () => <SOWCollectionModal />;

export default SOWCollection;
