import { useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';

import SOWCollectionFormContainer from './sow_collection_form_container';
import SOWCollectionSuccessModal from './sow_collection_success_modal';
import SOWCollectionPending from './sow_collection_pending';

const SOWCollectionApp = ({
  initializing,
  verificationPending,
  initializeSOWCollectionThunk,
  successSubmitModalVisible,
}) => {
  const history = useHistory();

  useEffect(() => {
    initializeSOWCollectionThunk();
  }, []);

  if (initializing) return <Spinner />;

  if (verificationPending) return <SOWCollectionPending />;

  return (
    <>
      <SOWCollectionFormContainer />
      {successSubmitModalVisible ? (
        <SOWCollectionSuccessModal onClose={() => history.goBack()} />
      ) : null}
    </>
  );
};

SOWCollectionApp.propTypes = {
  initializing: PropTypes.bool.isRequired,
  verificationPending: PropTypes.bool.isRequired,
  successSubmitModalVisible: PropTypes.bool.isRequired,
  initializeSOWCollectionThunk: PropTypes.func.isRequired,
};

export default SOWCollectionApp;
