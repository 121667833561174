import { connect } from 'react-redux';
import { compose } from 'underscore';
import { setCurrentStep, setForceValidation } from '../../duck';
import Steps from './steps';

const mapStateToProps = (state) => ({
  visible: state.sowCollection.stepper.visible,
  currentStep: state.sowCollection.stepper.currentStep,
  forceValidation: state.sowCollection.formConfiguration.forceValidation,
  stepperWithoutProgressBar: state.sowCollection.stepper.stepperWithoutProgressBar,
  areAdditionalIncomes: state.sowCollection.sowData.additionalIncome,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentStep: compose(dispatch, setCurrentStep),
  setForceValidation: compose(dispatch, setForceValidation),
});

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
