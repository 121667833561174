import PropTypes from 'prop-types';
import { Card } from 'bv-components';
import Step from './step';

const StepsWithBar = ({
  currentStep, steps, formInvalid, forceValidation, setCurrentStep,
}) => (
  <Card className="sow-collection-progress-bar">
    {
      Object.values(steps).map((step) => (
        <Step
          key={step.name}
          step={step}
          currentStep={steps[currentStep]}
          formInvalid={formInvalid}
          forceValidation={forceValidation}
          onStepClick={() => setCurrentStep(step.number)}
        />
      ))
    }
  </Card>
);

StepsWithBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.instanceOf(Object).isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default StepsWithBar;
