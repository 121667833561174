import { connect } from 'react-redux';
import { compose } from 'underscore';
import {
  onChangeMainSource,
} from '../duck';
import SOWCollectionMainStepForm from './sow_collection_main_step_form';

const mapStateToProps = (state) => ({
  fields: state.sowCollection.formConfiguration.step1,
  values: state.sowCollection.sowData,
  forceValidation: state.sowCollection.formConfiguration.forceValidation,
});

const mapDispatchToProps = (dispatch) => ({
  onChangeMainSource: compose(dispatch, onChangeMainSource),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOWCollectionMainStepForm);
