import PropTypes from 'prop-types';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const StepsWithoutBar = ({ currentStep, numberOfSteps, setCurrentStep }) => (
  <div className="sow-collection-steps">
    { currentStep > 1
      && (
      <div className="sow-collection-steps__back-button bvs-link" onClick={setCurrentStep}>
        <Icon id="arrow-left" type="regpath-back-arrow" />
        {t('javascript.account_form.step_back')}
      </div>
      )}
    <div className="sow-collection-steps__info">
      <span>
        {t('javascript.account_form.step_info', { current_step_number: currentStep, number_of_steps: numberOfSteps })}
      </span>
    </div>
  </div>
);

StepsWithoutBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default StepsWithoutBar;
