import { t } from 'bv-i18n';
import { Icon, Link, ButtonCard } from 'bv-components';

const SOWCollectionFooter = () => {
  const tKeyPrefix = 'contact_us_footer';
  return (
    <div className="modal_contact_footer">
      {t(`${tKeyPrefix}.need_help`)}
      <Link to="/pages/contact-us?first_modal=false">
        <ButtonCard>
          {`${t(`${tKeyPrefix}.contact_us`)} `}
          <Icon id="contact-us" />
        </ButtonCard>
      </Link>
    </div>
  );
};

export default SOWCollectionFooter;
