import { Card } from 'bv-components';
import PropTypes from 'prop-types';

import SOWCollectionFieldFactory from './sow_collection_field_factory';

const SOWCollectionAdditionalIncomeGroup = ({
  fields, values, index, formInvalid, forceValidation, onChange,
}) => {
  const ifFieldsInGroupRequired = () => {
    const notFilledAdditionalIncomes = Object.values(values[index])
      .filter((element) => !element).length;

    return notFilledAdditionalIncomes > 0
      && notFilledAdditionalIncomes !== Object.values(values[index]).length;
  };

  return (
    <Card>
      {fields.map((field) => {
        const name = `additionalIncomes[${index}][${field.questionName}]`;

        const {
          type, titleTranslationKey, questionName,
        } = field;
        return (
          <SOWCollectionFieldFactory
            key={name}
            name={name}
            type={type}
            forceValidation={ifFieldsInGroupRequired() || forceValidation}
            placeholder={titleTranslationKey}
            formInvalid={formInvalid}
            value={values[index][questionName]}
            onChange={(e) => onChange(index, questionName, e.target.value)}
          />
        );
      })}
    </Card>
  );
};

SOWCollectionAdditionalIncomeGroup.propTypes = {
  fields: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  formInvalid: PropTypes.bool.isRequired,
  forceValidation: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SOWCollectionAdditionalIncomeGroup;
